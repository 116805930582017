import React from 'react'
import { Link } from 'gatsby'
import {
  Container,
  Box,
  Text,
  Grid,
  Avatar,
  Button,
  Image,
  SimpleGrid,
} from '@chakra-ui/react'
import styled from 'styled-components'
import ReactLogo from '../../assets/logo.svg'
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'

const FooterWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 47px 0px 75px;
`

const FooterListUl = styled.ul`
  display: flex;
  flex-flow: column;

  @media (max-width: 479px) {
    align-items: center;
  }
`

const FooterListLi = styled.li`
  display: flex;
  font-size: 16px;
  color: #626262;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0px;
  }

  @media (max-width: 479px) {
    align-items: center;
  }
`

const AvatarWrapper = styled(Avatar)`
  :hover {
    background: #83b53c;
  }
  :hover .social_icon {
    color: white;
  }
`

const SubFooterWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 16px 0px;
  background: #1e3240;
`

const NewFooter = ({
  footerPart1,
  footerPart2,
  footerPart3,
  footerPart4,
  subFooterTitle,
}) => {
  return (
    <>
      <FooterWrapper>
        <Container
          centerContent
          maxW={[
            'container.sm',
            'container.md',
            'container.lg',
            'container.xl',
          ]}
        >
          <SimpleGrid
            columns={[1, 2, 2, 4]}
            spacing={['50px 0px', '50px 130px', '50px 160px', '50px 70px']}
          >
            <Box
              display="flex"
              flexFlow="column"
              alignItems={['center', 'flex-start', 'flex-start', 'flex-start']}
              textAlign={['center', 'left', 'left', 'left']}
            >
              {(footerPart1?.actions || []).map((item) => (
                <Box display={'inline-block'} as={Link} to={item.to}>
                  <Image
                    src={footerPart1.file.file.url}
                    alt="Logo"
                    w={'100%'}
                    maxWidth={{ base: '100px', xl: '153px' }}
                    cursor={'pointer'}
                  />
                </Box>
              ))}

              <Text fontSize={[, , , '16px']} color="#626262">
                {footerPart1.subtitle}
              </Text>
            </Box>

            <Box
              display="flex"
              flexFlow="column"
              alignItems={['center', 'flex-start', 'flex-start', 'flex-start']}
            >
              <Text
                as={'h3'}
                fontSize="24px"
                lineHeight="32px"
                fontWeight="bold"
                color="#6A6565"
                marginBottom="16px"
              >
                {footerPart2.title}
              </Text>
              {/* {console.log(footerPart2)} */}
              <FooterListUl>
                {(footerPart2?.pages || []).map((item, index) => (
                  <>
                    {/* {console.log(item)} */}
                    <FooterListLi key={index}>
                      <Link to={`/${item.slug}`}>{item.title}</Link>
                    </FooterListLi>
                  </>
                ))}
              </FooterListUl>
            </Box>
            <Box
              display="flex"
              flexFlow="column"
              alignItems={['center', 'flex-start', 'flex-start', 'flex-start']}
            >
              <Text
                as={'h3'}
                fontSize="24px"
                lineHeight="32px"
                fontWeight="bold"
                color="#6A6565"
                marginBottom="16px"
              >
                {footerPart3.title}
              </Text>
              {/* {console.log(footerPart3)} */}
              <FooterListUl>
                {(footerPart3?.pages || []).map((item, index) => (
                  <FooterListLi key={index}>
                    <Link to={`/${item.slug}`}>{item.title}</Link>
                  </FooterListLi>
                ))}
              </FooterListUl>
            </Box>
            <Box display="flex" flexFlow="column">
              <Box
                display="flex"
                flexFlow="column"
                alignItems={[
                  'center',
                  'flex-start',
                  'flex-start',
                  'flex-start',
                ]}
              >
                <Text
                  as={'h3'}
                  fontSize="24px"
                  lineHeight="32px"
                  fontWeight="bold"
                  color="#6A6565"
                  marginBottom="16px"
                >
                  {footerPart4.title}
                </Text>
                <FooterListUl>
                  {(footerPart4?.actions || []).map((item, index) => (
                    <FooterListLi key={index}>{item.text}</FooterListLi>
                  ))}
                </FooterListUl>
              </Box>
              <SimpleGrid
                columns={[4, 3, 4, 3, 4]}
                spacing={['10px 0px', '', '', '10px 25px']}
                marginTop="32px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={[
                    'center',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                  ]}
                >
                  <AvatarWrapper
                    bg="#FFFFFF"
                    height="44px"
                    width="44px"
                    boxShadow="0px 5px 14px rgba(0, 0, 0, 0.07)"
                    icon={<BsFacebook className="social_icon" />}
                    transition="all .3s ease-out"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={[
                    'center',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                  ]}
                >
                  <AvatarWrapper
                    bg="#FFFFFF"
                    height="44px"
                    width="44px"
                    boxShadow="0px 5px 14px rgba(0, 0, 0, 0.07)"
                    icon={<BsInstagram className="social_icon" />}
                    transition="all .3s ease-out"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={[
                    'center',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                  ]}
                >
                  <AvatarWrapper
                    bg="#FFFFFF"
                    height="44px"
                    width="44px"
                    boxShadow="0px 5px 14px rgba(0, 0, 0, 0.07)"
                    icon={<BsTwitter className="social_icon" />}
                    transition="all .3s ease-out"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={[
                    'center',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                  ]}
                >
                  <AvatarWrapper
                    bg="#FFFFFF"
                    height="44px"
                    width="44px"
                    boxShadow="0px 5px 14px rgba(0, 0, 0, 0.07)"
                    icon={<BsYoutube className="social_icon" />}
                    transition="all .3s ease-out"
                  />
                </Box>
              </SimpleGrid>
            </Box>
          </SimpleGrid>
        </Container>
      </FooterWrapper>
      <SubFooterWrapper>
        <Container
          centerContent
          maxW={[
            'container.sm',
            'container.md',
            'container.lg',
            'container.xl',
          ]}
        >
          <Box display="flex" justifyContent="center">
            <Text fontSize="14px" color="#FFFFFF" textAlign="center">
              {subFooterTitle}
            </Text>
          </Box>
        </Container>
      </SubFooterWrapper>
    </>
  )
}

export default NewFooter

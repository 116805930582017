import React from 'react'
import { SocialIcon } from './footer.style'
// import Instagram from '@cashremit/cr-streamline-icons/lib/bold/53-Logos/03-Photos-Images/SocialInstagram'
// import Facebook from '@cashremit/cr-streamline-icons/lib/bold/53-Logos/01-Social-Medias/SocialMediaFacebook1'
// import Twitter from '@cashremit/cr-streamline-icons/lib/bold/53-Logos/01-Social-Medias/SocialMediaTwitter'

const icons = {
  // facebook: Facebook,
  // twitter: Twitter,
  // instagram: Instagram,
}

export default function SocialLink({ link }) {
  return (
    <SocialIcon
      href={link.link}
      target="_blank"
      data-key={`footer__social-icons__${link.title}`}
    >
      {/* {React.createElement(icons[link.title], {
        size: 'sm',
        color: '#ddd',
      })} */}
    </SocialIcon>
  )
}

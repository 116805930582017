import React from 'react'
import Footer from '../containers/Footer/footer'
import NewFooter from '../components/NewFooter/index'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  float: left;
  width: 100%;
`

class Template extends React.Component {
  componentDidMount() {
    console.log('gd_mount')
    window['scrollTo']({ top: 0 })
  }
  render() {
    const { location, children } = this.props
    return (
      <>
        <Helmet></Helmet>
        <Content>{children}</Content>
        {/* <NewFooter /> */}
        {/* <Footer /> */}
      </>
    )
  }
}

export default Template

import React from 'react'
import PropTypes from 'prop-types'
import SocialLink from './SocialLink'
import NavigationColumn from './NavigationColumn'
import {
  CallButton,
  Container,
  SocialIconsContainer,
  Wrapper,
  ContentWrapper,
  ListItemWrapper,
} from './footer.style'

const Footer = ({
  columnOneLinks,
  columnTwoLinks,
  phone,
  email,
  socialLinks,
}) => {
  const navigationColumns = [
    {
      links: columnOneLinks,
    },
    {
      links: columnTwoLinks,
    },
  ].filter(({ links }) => links)
  return (
    <Wrapper>
      <ContentWrapper>
        <ListItemWrapper>
          {(navigationColumns || []).map((column, index) => {
            const showBorder = index !== navigationColumns.length - 1
            return (
              column.links.length && (
                <NavigationColumn
                  key={`footer-nav-${index}`}
                  links={column.links}
                  dataKey={`footer-nav-${index}`}
                />
              )
            )
          })}
        </ListItemWrapper>
        <Container>
          <SocialIconsContainer>
            {socialLinks &&
              (socialLinks || []).map((link) => (
                <SocialLink key={`footer-social-${link.title}`} link={link} />
              ))}
          </SocialIconsContainer>
          {phone && <CallButton>{phone}</CallButton>}
          {email && <CallButton>{email}</CallButton>}
        </Container>
      </ContentWrapper>
    </Wrapper>
  )
}
Footer.propTypes = {
  logo: PropTypes.node.isRequired,
  phone: PropTypes.node,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
    })
  ),
  columnOneLinks: PropTypes.arrayOf(PropTypes.node),
  columnOneTitle: PropTypes.string.isRequired,
  columnTwoLinks: PropTypes.arrayOf(PropTypes.node),
  columnTwoTitle: PropTypes.string,
}
export default Footer

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-layout-js": () => import("./../../../src/templates/PageLayout.js" /* webpackChunkName: "component---src-templates-page-layout-js" */),
  "component---src-templates-product-layout-js": () => import("./../../../src/templates/ProductLayout.js" /* webpackChunkName: "component---src-templates-product-layout-js" */)
}

